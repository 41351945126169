<template>
  <div class="process-section pt-100 pb-70 bg-off-white">
    <div class="container">
      <div class="section-title section-title-lg">
        <small>接入流程</small>
        <h2>10 分钟完成设备接入</h2>
      </div>
      <div class="process-content">
        <div class="process-content-line"></div>
        <div class="row justify-content-center">
          <div class="col-sm-6 col-lg-4 pb-30">
            <div class="process-item process-item-center border">
              <div class="process-thumb">
                <img
                  src="../../assets/images/process/process-one.png"
                  alt="shape"
                />
              </div>
              <div class="process-text">
                <h3>联网设备</h3>
                <p>
                  通过MQTT、HTTP及自定义协议，接接入到平台或者通过网关/边缘接入平台
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 pb-30">
            <div class="process-item process-item-center border">
              <div class="process-thumb">
                <img
                  src="../../assets/images/process/process-two.png"
                  alt="shape"
                />
              </div>
              <div class="process-text">
                <h3>匠心云平台</h3>
                <p>
                  创建设备、适配物模型，选择接入方式，定义数据解析规则后即看实时可查设备数据。
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 pb-30">
            <div class="process-item process-item-center border">
              <div class="process-thumb">
                <img
                  src="../../assets/images/process/process-three.png"
                  alt="shape"
                />
              </div>
              <div class="process-text">
                <h3>可视化看板</h3>
                <p>
                  创建可视化看板后，选择适合的可视化模型与数据模型绑定，即可搭建出功能强大的视图看板。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Process",
};
</script>