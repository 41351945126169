<template>
  <div class="about-section bg-off-white pt-100 pb-70">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 pb-30">
          <div class="about-section-item about-item-image text-center">
            <img
              src="../../assets/images/feature-shape/feature-shape-1.png"
              alt="shape"
            />
          </div>
        </div>
        <div class="col-lg-6 pb-30">
          <div class="about-section-item about-item-details">
            <div class="section-title section-title-left text-start">
              <small>关于我们</small>
              <h2>让万物互联更简单</h2>
            </div>
            <div class="about-content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </p>
              <ul>
                <li>Advance Advisory Team</li>
                <li>Advance Quality Experts</li>
                <li>Many variations of passages</li>
                <li>High-Quality Results</li>
              </ul>
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia
                consequuntur magni.
              </p>
              <router-link to="/about-us" class="btn main-btn"
                >关于我们</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>