<template>
  <div class="trial-section pt-min-100 pb-100 bg-main page-bg page-bg-1">
    <div class="container">
      <div class="section-title section-title-lg section-title-white mb-0">
        <h2>为开发提供开箱即用的服务</h2>
        <p>
          在设备和用户之间建立开箱即用的云平台，实现数据采集、数据可视化、告警监控、开放
          API，构建灵活强大的物联网应用
        </p>
        <!-- <router-link
          to="/contact"
          class="btn main-btn main-btn-white main-btn-rounded-icon"
        >
          Try 14 Days Free Trial
          <span><i class="icofont-rounded-right"></i></span>
        </router-link> -->

        <a
          href="https://iot.dunji.tech"
          target="_blank"
          class="btn main-btn main-btn-white main-btn-rounded-icon"
        >
          开始试用
          <span><i class="icofont-rounded-right"></i></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FreeTrial",
};
</script>