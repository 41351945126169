<template>
  <footer class="bg-off-white footer">
    <div class="footer-shapes">
      <div class="footer-shape">
        <img src="../../assets/images/shape-1.png" alt="shape" />
      </div>
      <div class="footer-shape footer-round-shape">
        <img src="../../assets/images/shape-2.png" alt="shape" />
      </div>
    </div>
    <div class="footer-upper pt-100 pb-80 position-relative">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-3">
            <div class="footer-content-item">
              <div class="footer-logo">
                <router-link to="/">
                  <img src="../../assets/images/logo.png" alt="logo" />
                </router-link>
              </div>
              <div class="footer-details">
                <p>关注二维码，获取支持</p>
                <p>
                  <img
                    style="width: 120px; height: 120px; margin-left: 20px"
                    src="../../assets/images/contact_me_qr.png"
                    alt="logo"
                  />
                  <br />
                </p>

                <!-- <ul class="social-list social-list-btn">
                  <li>
                    <a href="https://www.facebook.com/" target="_blank">
                      <i class="icofont-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/" target="_blank">
                      <i class="icofont-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank">
                      <i class="icofont-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.pinterest.com/" target="_blank">
                      <i class="icofont-pinterest"></i>
                    </a>
                  </li>
                </ul> -->
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-3">
            <div
              class="footer-content-list footer-content-item desk-pad-left-70"
            >
              <div class="footer-content-title">
                <h3>友情链接</h3>
              </div>
              <ul class="footer-details footer-list">
                <li>
                  <a href="http://www.wiotc.org" target="_blank"
                    >世界物联网大会</a
                  >
                </li>
                <li>
                  <a href="http://www.shanghaiiot.org" target="_blank"
                    >上海市物联网行业协会</a
                  >
                </li>
                <li>
                  <a href="https://mqtt.org" target="_blank">MQTT 协议</a>
                </li>
                <li>
                  <a href="http://www.crvic.org" target="_blank"
                    >中国RISC-V产业联盟（CRVIC）</a
                  >
                </li>

                <li>
                  <a href="https://www.csia.org.cn" target="_blank"
                    >中国软件行业协会</a
                  >
                </li>
                <!-- <li><router-link to="/contact">Contact</router-link></li> -->
              </ul>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-3">
            <div
              class="footer-content-list footer-content-item desk-pad-left-70"
            >
              <div class="footer-content-title">
                <h3>相关服务</h3>
              </div>
              <ul class="footer-details footer-list">
                <li>
                  <router-link to="/">设备接入管理</router-link>
                </li>
                <li>
                  <router-link to="/">支持的通讯协议</router-link>
                </li>
                <li>
                  <router-link to="/">安全和身份验证</router-link>
                </li>
                <li>
                  <router-link to="/">数据存储与可视化</router-link>
                </li>
                <li>
                  <router-link to="/">基于API业务集成</router-link>
                </li>
                <li>
                  <router-link to="/">数字孪生模型</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-3">
            <div
              class="footer-content-list footer-content-item desk-pad-left-70"
            >
              <div class="footer-content-title">
                <h3>联系我们</h3>
              </div>
              <div class="footer-details footer-address">
                <!-- <div class="footer-address-item">
                  <div class="footer-address-text">
                    <h4>手机:</h4>
                    <p><a href="tel:13671621389">(+86) 136 7162 1389</a></p>
                  </div>
                </div> -->
                <div class="footer-address-item">
                  <div class="footer-address-text">
                    <h4>电子邮箱:</h4>
                    <p>
                      <a href="mailto:support@dunji.tech">support@dunji.tech</a>
                    </p>
                  </div>
                </div>
                <div class="footer-address-item">
                  <div class="footer-address-text">
                    <h4>地址:</h4>
                    <p>上海市松江区联营路615号企福新尚科技园2期45号楼5层</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-lower bg-blue position-relative">
      <div class="container">
        <div class="footer-copyright-text footer-copyright-text-white">
          <p>
            Copyright ©2018-{{ currentYear }} 上海顿匠信息科技有限公司.
            沪ICP备2022000067号-1
            <!-- <a href="https://envytheme.com/" target="_blank">EnvyTheme</a> -->
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>