<template>
    <div class="team-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 pb-30">
                    <div class="team-card">
                        <div class="team-card-thumb">
                            <img src="../../assets/images/team/team-2.jpg" alt="team">
                            <ul class="team-social-list">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                            </ul>
                        </div>
                        <div class="team-card-content">
                            <h3>John Andre</h3>
                            <h4>Software Engineer</h4>
                            <p>Lorem ipsum dolor sit amet, con amit sectetur adipisicing elit.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 pb-30">
                    <div class="team-card">
                        <div class="team-card-thumb">
                            <img src="../../assets/images/team/team-1.jpg" alt="team">
                            <ul class="team-social-list">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                            </ul>
                        </div>
                        <div class="team-card-content">
                            <h3>Isabella Tomas</h3>
                            <h4>Researcher</h4>
                            <p>Lorem ipsum dolor sit amet, con amit sectetur adipisicing elit.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 pb-30">
                    <div class="team-card">
                        <div class="team-card-thumb">
                            <img src="../../assets/images/team/team-3.jpg" alt="team">
                            <ul class="team-social-list">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                            </ul>
                        </div>
                        <div class="team-card-content">
                            <h3>Richard Clark</h3>
                            <h4>Software Developer</h4>
                            <p>Lorem ipsum dolor sit amet, con amit sectetur adipisicing elit.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 pb-30">
                    <div class="team-card">
                        <div class="team-card-thumb">
                            <img src="../../assets/images/team/team-4.jpg" alt="team">
                            <ul class="team-social-list">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                            </ul>
                        </div>
                        <div class="team-card-content">
                            <h3>Mike Peterson</h3>
                            <h4>Data Analyst</h4>
                            <p>Lorem ipsum dolor sit amet, con amit sectetur adipisicing elit.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 pb-30">
                    <div class="team-card">
                        <div class="team-card-thumb">
                            <img src="../../assets/images/team/team-5.jpg" alt="team">
                            <ul class="team-social-list">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                            </ul>
                        </div>
                        <div class="team-card-content">
                            <h3>John Doe</h3>
                            <h4>Software Developer</h4>
                            <p>Lorem ipsum dolor sit amet, con amit sectetur adipisicing elit.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 pb-30">
                    <div class="team-card">
                        <div class="team-card-thumb">
                            <img src="../../assets/images/team/team-6.jpg" alt="team">
                            <ul class="team-social-list">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="flaticon-facebook"></i></a></li>
                                <li><a href="https://twitter.com/" target="_blank"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                            </ul>
                        </div>
                        <div class="team-card-content">
                            <h3>David Kotlin</h3>
                            <h4>Data Analyst</h4>
                            <p>Lorem ipsum dolor sit amet, con amit sectetur adipisicing elit.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Team'
}
</script>