<template>
  <div class="service-section pt-100 pb-70">
    <div class="container">
      <div class="section-title section-title-lg">
        <small>平台功能</small>
        <h2>快速选择您所需的服务</h2>
        <p>
          提供极简接入、智能化、安全可信的开发、托管服务，助力企业和个人开发者快速地构建物联网解决方案
          <!-- 物联网应用和物联网设备之间搭建高效、稳定、安全的应用平台 -->
        </p>
      </div>
      <div class="row">
        <div class="col-md-6 col-lg-4 pb-30">
          <div class="service-card">
            <div class="service-card-thumb">
              <i class="flaticon-automation"></i>
            </div>
            <div class="service-card-body">
              <h3>轻松接入设备</h3>
              <p>
                支持多网络、多协议接入，任何硬件设备都可直连或通过网关接入。
              </p>
              <!-- <router-link to="/single-services" class="redirect-link">
                Discover More
                <i class="icofont-rounded-right"></i>
              </router-link> -->
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 pb-30">
          <div class="service-card">
            <div class="service-card-thumb">
              <i class="flaticon-predictive-chart"></i>
            </div>
            <div class="service-card-body">
              <h3>自定义数据解析</h3>
              <p>提供消息解析功能，可以轻松将设备数据转换为业务数据。</p>
              <!-- <router-link to="/single-services" class="redirect-link">
                Discover More
                <i class="icofont-rounded-right"></i>
              </router-link> -->
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 pb-30">
          <div class="service-card">
            <div class="service-card-thumb">
              <i class="flaticon-deep-learning"></i>
            </div>
            <div class="service-card-body">
              <h3>设备管理</h3>
              <p>
                功能包括：自定义设备物模型、设备实时状态监控、预警规则、设备远程诊断。
              </p>
              <!-- <router-link to="/single-services" class="redirect-link">
                Discover More
                <i class="icofont-rounded-right"></i>
              </router-link> -->
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 pb-30">
          <div class="service-card">
            <div class="service-card-thumb">
              <i class="flaticon-data-mining"></i>
            </div>
            <div class="service-card-body">
              <h3>数字孪生</h3>
              <p>
                通过3D建模与物联网技术结合，构建虚拟工厂，提高生产过程可控性。
              </p>
              <!-- <router-link to="/single-services" class="redirect-link">
                Discover More
                <i class="icofont-rounded-right"></i>
              </router-link> -->
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 pb-30">
          <div class="service-card">
            <div class="service-card-thumb">
              <i class="flaticon-diagram"></i>
            </div>
            <div class="service-card-body">
              <h3>应用集成</h3>
              <p>平台提供所有功能API接口，第三方应用可轻松获取平台数据。</p>
              <!-- <router-link to="/single-services" class="redirect-link">
                Discover More
                <i class="icofont-rounded-right"></i>
              </router-link> -->
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 pb-30">
          <div class="service-card">
            <div class="service-card-thumb">
              <i class="flaticon-machine-learning-2"></i>
            </div>
            <div class="service-card-body">
              <h3>通知管理</h3>
              <p>平台支持钉钉、微信、邮箱、短信、webhook等通知方式。</p>
              <!-- <router-link to="/single-services" class="redirect-link">
                Discover More
                <i class="icofont-rounded-right"></i>
              </router-link> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>