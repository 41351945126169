<template>
  <div class="feature-section pt-min-100 pb-70">
    <div class="container">
      <div class="section-title section-title-lg">
        <h2>
          物联网无处不在
          <br />
          构建灵活强大的物联网应用
        </h2>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-3 pb-30">
            <div class="feature-card">
              <div class="feature-card-header">
                <div class="feature-card-thumb feature-card-thumb-green">
                  <i class="flaticon-machine-learning-1"></i>
                </div>
                <span>安全可靠</span>
              </div>
              <div class="feature-card-body">
                <p>支持一机一密，基于TLS、DTLS加密协议，提供安全的传输通道。</p>
                <!-- <router-link to="/single-services" class="redirect-link">
                  Discover More
                  <i class="icofont-rounded-right"></i>
                </router-link> -->
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 pb-30">
            <div class="feature-card">
              <div class="feature-card-header">
                <div class="feature-card-thumb feature-card-thumb-yellow">
                  <i class="flaticon-voice-recognition"></i>
                </div>
                <span>性能稳定</span>
              </div>
              <div class="feature-card-body">
                <p>服务资源可弹性扩展，支持百万级设备接入。</p>
                <!-- <router-link to="/single-services" class="redirect-link">
                  Discover More
                  <i class="icofont-rounded-right"></i>
                </router-link> -->
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 pb-30">
            <div class="feature-card">
              <div class="feature-card-header">
                <div class="feature-card-thumb feature-card-thumb-blue">
                  <i class="flaticon-machine-learning"></i>
                </div>
                <span>便捷数据服务</span>
              </div>
              <div class="feature-card-body">
                <p>可轻松的定义设备数据清洗规则，可以过滤、聚合设备数据。</p>
                <!-- <router-link to="/single-services" class="redirect-link">
                  Discover More
                  <i class="icofont-rounded-right"></i>
                </router-link> -->
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 pb-30">
            <div class="feature-card">
              <div class="feature-card-header">
                <div class="feature-card-thumb feature-card-thumb-violet">
                  <i class="flaticon-processor"></i>
                </div>
                <span>可视化数据管理</span>
              </div>
              <div class="feature-card-body">
                <p>将设备上报的大量数据图形可视化、场景化以及实时交互。</p>
                <!-- <router-link to="/single-services" class="redirect-link">
                  Discover More
                  <i class="icofont-rounded-right"></i>
                </router-link> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Feature",
};
</script>