<template>
    <div class="contact-info-section pt-100 pb-100">
        <div class="container">
            <div class="section-title section-title-lg">
                <small>Contact Information</small>
                <h2>Let's Talk About For Your Business</h2>
                <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris.</p>
            </div>
            <!-- Contact-info -->
            <div class="contact-info mb-15">
                <div class="row">
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="contact-info-item">
                            <div class="contact-info-header">
                                <div class="contact-info-header-icon">
                                    <i class="icofont-location-pin"></i>
                                </div>
                                <div class="contact-info-header-text">
                                    <h3>Location</h3>
                                    <p>Visit Our Office</p>
                                </div>
                            </div>
                            <div class="contact-info-body">
                                <p>214, Queens Road 4th Cross</p>
                                <p>New York, 5240</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="contact-info-item">
                            <div class="contact-info-header">
                                <div class="contact-info-header-icon">
                                    <i class="icofont-ui-call"></i>
                                </div>
                                <div class="contact-info-header-text">
                                    <h3>Make A Call</h3>
                                    <p>Let’s Talk with our Experts</p>
                                </div>
                            </div>
                            <div class="contact-info-body">
                                <p>
                                    <a href="tel:00321-654-98">+00 321 654 98</a>
                                </p>
                                <p>Mon - Fri: 09.00 to 17.00</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 pb-30 offset-lg-0 offset-md-3">
                        <div class="contact-info-item">
                            <div class="contact-info-header">
                                <div class="contact-info-header-icon">
                                    <i class="icofont-email"></i>
                                </div>
                                <div class="contact-info-header-text">
                                    <h3>Send An Email</h3>
                                    <p>Don’t Hesitate to Email</p>
                                </div>
                            </div>
                            <div class="contact-info-body">
                                <p>
                                    <a href="mailto:info@lada.com">info@lada.com</a>
                                </p>
                                <p>
                                    <a href="mailto:support@lada.com">support@lada.com</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Contact-info -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactInfo'
}
</script>